import logo from './logo.svg';
import Navbar from './components/Navbar';
import {BrowserRouter as Router, Switch, Route} from 'react-router-dom';
import './App.css';

function App() {
  return (
    <div className="App lk1">
      <header className="App-header">
      <div className="logo-block">
          <img src={logo} className="logo-p" alt="logo" />
          <a href="/" className='logo-t'>smilescorp::</a>
        </div>
        <Router>
          <Navbar/>
          <Switch>
            <Route path='/' exact/>
          </Switch>
        </Router>
      </header>
    </div>
  );
}

export default App;
